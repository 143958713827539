@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(fonts/diagram/segoeui.css);
@import url(fonts/stix/stix.css);

.ms-editor {
    position: relative;
    z-index: 20;
    color: #1A9FFF;
    font-family: sans-serif;
}

.ms-editor canvas,
.ms-editor svg {
    z-index: 15;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.ms-editor canvas.ms-rendering-canvas,
.ms-editor svg {
    z-index: 10;
    pointer-events: none;
    background: repeating-linear-gradient(180deg, #F5F6F7, #F5F6F7 1px, transparent 1px, transparent 18px), repeating-linear-gradient(90deg, #F5F6F7, #F5F6F7 1px, transparent 1px, transparent 18px);
}

.ms-editor .loader {
    z-index: 30;
    position: absolute;
    width: 120px;
    height: 120px;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    border: 16px solid #F5F6F7;
    border-radius: 50%;
    border-top-color: #1A9FFF;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.ms-editor .error-msg {
    z-index: 25;
    position: absolute;
    width: 200px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    font-size: 16px;
    text-align: center;
    word-wrap: break-word;
}

.ms-editor .error-msg::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCA3Ni41IDYxMiA0NTkiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIG1lZXQiPgogICAgPHBhdGggZmlsbD0iIzFBOUZGRiIgZD0iTTQ5NC43LDIyOS41Yy0xNy44NTEtODYuNy05NC4zNTEtMTUzLTE4OC43LTE1M2MtMzguMjUsMC03My45NSwxMC4yLTEwMiwzMC42bDM4LjI1LDM4LjI1IGMxNy44NS0xMi43NSw0MC44LTE3Ljg1LDYzLjc1LTE3Ljg1Yzc2LjUsMCwxNDAuMjUsNjMuNzUsMTQwLjI1LDE0MC4yNXYxMi43NWgzOC4yNWM0My4zNSwwLDc2LjUsMzMuMTUsNzYuNSw3Ni41IGMwLDI4LjA1LTE1LjMsNTMuNTUtNDAuOCw2Ni4zbDM4LjI1LDM4LjI1QzU5MS42LDQzOC42LDYxMiw0MDAuMzUsNjEyLDM1N0M2MTIsMjkwLjcsNTU4LjQ1LDIzNC42LDQ5NC43LDIyOS41eiBNNzYuNSwxMDkuNjUgbDcxLjQsNjguODVDNjYuMywxODMuNiwwLDI0OS45LDAsMzMxLjVjMCw4NC4xNSw2OC44NSwxNTMsMTUzLDE1M2gyOTguMzVsNTEsNTFsMzMuMTUtMzMuMTVMMTA5LjY1LDc2LjVMNzYuNSwxMDkuNjV6IE0xOTYuMzUsMjI5LjVsMjA0LDIwNEgxNTNjLTU2LjEsMC0xMDItNDUuOS0xMDItMTAyYzAtNTYuMSw0NS45LTEwMiwxMDItMTAySDE5Ni4zNXoiIC8+Cjwvc3ZnPgo=);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-plan {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    25% {
        transform: perspective(120px) rotateX(0deg) rotateY(-180deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
    75% {
        transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
}

@-webkit-keyframes rotate-plan {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
    25% {
        -webkit-transform: perspective(120px) rotateX(180deg) rotateY(0deg)
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(180deg) rotateY(180deg)
    }
    75% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(180deg)
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
